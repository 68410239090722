import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map  } from 'rxjs/operators';
import { WebsocketService } from '../../core/services/websocket.service';
import { HttphandlerService } from '../../core/services/httphandler.service';
import { environment } from '../../../environments/environment';

const URL = 'wss://xps2fmlaw5.execute-api.us-east-1.amazonaws.com/production';

@Injectable({
  providedIn: 'root'
})
export class AlarmasService {

    public messages    : Subject<any> = new Subject<any>();
    public alarmas      : any[];
    public alarmaslegth: number;

    constructor(private wsService: WebsocketService,private httpService: HttphandlerService) {
     this.messages = <Subject<any>>this.wsService
      .connect(URL)
      .pipe(
        map((response: MessageEvent): any => {
          let data = JSON.parse(response.data);

          return {
            data: data
          };
     }));


    }

    public sendMessage(message) {
      this.messages.next(message);
    }

    public getDatosAlarmas(codUsuario: string) {

      const url = environment.urlapi.default+"equipo-alarma?usuario="+codUsuario;

      this.httpService.httpGetData(url)
      .subscribe((data) => {

        this.alarmas = data["data"];
        this.alarmaslegth = this.alarmas.length;

      },
      (err)=>{

        console.log(err);
      });

    }
}
