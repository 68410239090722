/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

//// Datos de DEMO//////////////amplify_backend_manager_d3fz4ms1huqp6a   amplify_backend_manager_d3fz4ms1huqp6a

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:caf983ea-c6bc-4436-85dd-a9cbc0228cc0",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_nDPsUdcKZ",
  "aws_user_pools_web_client_id": "m59pfjfqcfid0hgjlatfk15c4",
  "oauth": {},
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cloud_logic_custom": []

};

export default awsmobile;
