// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  defaultauth: 'aws',

  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  confirm: {
   email: '',
   password: ''
  },
  urlapi:{
    default:'https://gqrpxgg7uh.execute-api.us-east-1.amazonaws.com/dev/',
    recom:'https://apirecom.demo.reliabytics.com/',
    analisrbi:'https://apirbi.demo.reliabytics.com/',
    rondasoper:'https://apironda.demo.reliabytics.com/',
    tareas:'https://apitarea.demo.reliabytics.com/',
    maquetaforge:"https://api.modelo3d.reliabytics.com/api/forge/oauth/token"
  },
  identityPoolId:'us-east-1:2108f6b3-33f1-4072-a799-510bfb30d754',
  bucketName: 'reliabytics-cardoniv-desa'
};




